<template>
  <div class="d-flex align-items-center flex-column reorder-mobile">
    <ion-button class="btn" :class="{ disabled: index === 0 }" :disabled="index === 0" @click="up">
      <ion-icon :icon="caretUp" />
    </ion-button>
    <div class="order reorder-order d-flex align-items-center justify-content-center">
      {{ leadingZero(index + 1) }}
    </div>
    <ion-button class="btn" :class="{ disabled: index + 1 === total }" :disabled="index + 1 === total" @click="down">
      <ion-icon :icon="caretDown" />
    </ion-button>
  </div>
</template>

<script lang="ts" setup>
import { caretUp, caretDown } from 'ionicons/icons';
import { leadingZero } from '@/shared/utils/number';

const emit = defineEmits(['reorder']);

const props = defineProps({
  index: {
    type: Number,
    default: 0,
  },
  total: {
    type: Number,
    default: 0,
  },
});

const index = toRef(props, 'index');
const total = toRef(props, 'total');

const up = () => {
  const data = {
    detail: {
      from: index.value,
      to: index.value - 1,
    },
    complete: () => {},
  };
  emit('reorder', data);
};

const down = () => {
  const data = {
    detail: {
      from: index.value,
      to: index.value + 1,
    },
    complete: () => {},
  };
  emit('reorder', data);
};
</script>

<style lang="sass" scoped>
.reorder-mobile
  .order
    width: 37px
    height: 30px
    min-width: 30px
    border-radius: 5px
    margin: auto
    background-color: #fff
  .btn
    width: 37px
    height: 30px
    font-size: 10px
    border-radius: 5px
    color: black
  .disabled
    opacity: 0.4
    pointer-events: none
</style>
